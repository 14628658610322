import { useEffect, useState } from 'react';
import TaxonsService from '../../Services/TaxonsService.ts';
import { TaxonInterface } from '../../interfaces/Taxon.ts';
import { TaxonImageInterface } from '../../interfaces/TaxonImage.ts';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import '@styles/templates/menu/shop-menu.scss';
import { useProductRoutePrefix } from '../../ProductRoutePrefixContext.tsx';

const ShopSubMenuTabContentStyled = styled.div`
    position: absolute;
    left: 0;
    display: none;
    width: 100%;
    padding: 20px 0;

    ${props => props.theme.devices.fullhdRealWidth} {
        padding-left: 5%;
        padding-right: 5%;
    }

    .column {
        padding-left: 5px;
        white-space: normal;
    }

    .sub-title-1 {
        font-family: ${props => props.theme.fonts.familySecondary};
    }

    ol > li > a {
        font-size: ${props => props.theme.globalSize.size7};
        font-weight: 400;
        line-height: 18px;

        justify-content: left;
        padding: 5px 0;
        border-bottom: none;

        &:hover {
            color: ${props => props.theme.colors.secondary};
        }
    }

    ol.two-columns {
        padding: 20px 0;
        column-count: 2;
        column-gap: 20px;
    }
`;

export default function ShopMenu() {
    const [taxons, setTaxons] = useState<TaxonInterface[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const prefix = useProductRoutePrefix();

    useEffect(() => {
        const service = new TaxonsService();
        service.getAllTaxons().then(data => {
            setTaxons(data);
            setIsLoaded(true);
        });
    }, []);

    if (!isLoaded) {
        return <div>Loading ... </div>;
    }

    const displayImage = (taxon: TaxonInterface) => {
        const images: Array<TaxonImageInterface> = taxon.images?.filter((image: TaxonImageInterface) => image.type === 'menu') || [];
        if (!images || images.length === 0) {
            return null;
        }

        const image = images.pop();

        return (
            <div className='column is-half'>
                <img
                    src={image?.path}
                    alt={taxon.name}
                />
            </div>
        );
    };

    const displayChildren = (taxon: TaxonInterface) => {
        if (!taxon.children || taxon.children.length === 0) {
            return null;
        }

        return taxon.children.map(
            (childTaxon: TaxonInterface) =>
                childTaxon.enabled && (
                    <div
                        className='column is-one-third'
                        key={childTaxon.id}
                    >
                        <div className='has-text-weight-bold'>{childTaxon.name}</div>
                        {childTaxon.children && childTaxon.children.length > 0 && (
                            <ol className='two-columns'>
                                {childTaxon.children.map((child: TaxonInterface) => (
                                    <li key={child.id}>
                                        <a href={'#'}> {child.name}</a>
                                    </li>
                                ))}
                            </ol>
                        )}
                    </div>
                ),
        );
    };

    return (
        <>
            <div id='menu-shop-content'>
                <div className='tabs is-fullwidth'>
                    <ul className={'is-justify-content-space-between'}>
                        {taxons &&
                            taxons.map((taxon: TaxonInterface) => (
                                <div key={taxon.id}>
                                    <li className={'shop-sub-menu-tab'}>
                                        <Link
                                            to={`${prefix}/taxons/${taxon.code}`}
                                            className={'navbar-item is-tab'}
                                        >
                                            {taxon.name}
                                        </Link>

                                        <ShopSubMenuTabContentStyled className='shop-sub-menu-tab-content'>
                                            <div className='container is-fluid'>
                                                <div className='columns'>
                                                    {displayChildren(taxon)}

                                                    <div className='column is-one-third'>
                                                        <div className='columns'>{displayImage(taxon)}</div>

                                                        <div className='column is-half'>{taxon.description}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ShopSubMenuTabContentStyled>
                                    </li>
                                </div>
                            ))}
                    </ul>
                </div>
            </div>
        </>
    );
}
